import React from "react"
import Layout from "../components/layout"
import {
  Box,
  Text,
  Image,
  Spacer,
  Center,
  Stack,
} from "@chakra-ui/react"
import anne from "../media/Griechenland.jpeg"
import haus1 from "../media/Haus1.jpeg"
import haus2 from "../media/Haus2.jpeg"
import haus3 from "../media/Haus3.jpeg"
import Zimmer from "../media/Zimmer.jpeg"
import Griechenland2 from "../media/Griechenland2.jpeg"
import Elink from "../components/elink"

export default function Home() {
  return (
    <Layout title="Retreats" slug="yoga/">
      <Box textAlign="center">
        <Text fontSize="4xl" as="b">
          Yoga Retreat in Griechenland <br /> 02-Sep-2023 - 09-Sep-2023
        </Text>
      </Box>
      <br />
<Spacer />
  <Center>
        <Box>
          <Image src={anne} alt="Foto Griechenland" />
            <br />
              <Box textAlign="center">
                  <Text frontSize="4xl" mt={12} mb={3} as="b">
                  Hatha & Restorative Yoga mit Sahra & Anne
                  </Text>
                </Box>
                <Box textAlign="center">
                    <Text frontSize="4xl" mt={12} mb={3} as="b">
                    Genießt mit uns eine Woche Yoga am Mittelmeer!
                    </Text>
                  </Box>
                      <br />
                      <Elink href="https://www.sahrabehagh.de/%C3%BCber-mich/"
                        title="Sahra Isabell Behagh">Hier erfährst du mehr über Sahra.
                      </Elink>{" "}
                      <br />
                      <br />
                        <Text>
                        Wir beginnen jeden Morgen mit einer aktivierenden Yogaeinheit und Meditation. Dabei führen wir dich durch die Welt der 5 Elemente. Unser Stil ist klar und ausrichtungsorientiert. Am Abend erwartet dich eine Restorative Yogapraxis mit Elementen aus der chinesischen Medizin, Faszientherapie, Osteopathie, Stimmgabeltherapie sowie wohltuende Hands On, Mudra- und Atempraxis.  
                        </Text>
                </Box>
        </Center>
<Spacer />

      <br />
        <Spacer />
          <Center>
              <Box>
              <Stack direction='row'>
                <Image boxSize='50%' objectFit='fill' src={Zimmer} alt='Zimmer'/>
                <Image boxSize='50%' objectFit='fill' src={Griechenland2} alt='Griechenland2'/>
              </Stack>
                <br />
              </Box>
          </Center>
        <Spacer />
<Box textAlign="center">
<Text frontSize="lg" as="b"> Das Programm</Text>
</Box>
<br />
<Text>TAG 1:</Text>
<Text>Anreise und Abendessen</Text>
<br />
<Text>TAG 2-7:</Text>
<Text> Hatha Yoga Praxis, Frühstück, Freizeit/ Ausflüge, Restorative Yoga, Abendessen, Freizeit/ Mantrasingen</Text>
<br />
<Text>TAG 8:</Text>
<Text>Frühstück und Abreise</Text>
<br />
<br />
<Box textAlign="center">
      <Text frontSize="lg" as="b">
      Unterkunft
      </Text>
</Box>
<br />
  <Text>Sea & Soul Yoga Retreat Greece liegt in dem süßen Fischerdorf Kiveri, auf dem Peloponnes. Authentisch, ursprünglich, nur in den Sommermonaten Juli und August lebendiger, kannst du hier abschalten. 
  </Text>
  <br />
  <Text>Die kleine, familiengeführte Pension liegt direkt am Meer. Keine fünf Schritte trennen dich von einem morgendlichen Sprung ins Meer oder in den Pool. Alle Zimmer verfügen über einen Kühlschrank, einen Wasserkocher, einen kleinen Kaffeeautomaten und ein eigenes Badezimmer.  
  </Text>
  <br />
  <Text>Direkt neben dem Hotel befindet sich unser Yogastudio. Es ist ausgestattet mit hochwertigem Equipment wie Yogamatten, Blöcke, Gurte und Decken, so dass du nichts extra einpacken musst. Außerdem können Yoga Klassen auch auf das Roof Top oder ans Meer verlagert werden. 
  </Text>
  <br />
  <Text>Nach der ersten Yogaeinheit des Tages genießt du im Summer Café ein frisch zubereitetes Frühstück. Egal ob vegan oder vegetarisch - es bleiben keine Wünsche offen. 
  </Text>
  <br />
  <Text> Preise:
  </Text>
  <br />
  <Text> Doppelzimmer mit Gartenblick: 1099 € p.P.
  </Text>
  <Text> Einzelzimmer mit Gartenblick: 1199 € p.P.
  </Text>
  <Text> Doppelzimmer mit Meerblick: 1199 € p.P.
  </Text>
  <Text> Einzelzimmer mit Meerblick: 1299 € p.P.
  </Text>
  <br />
  <Text> Im Preis enthalten:
  </Text>
  <br />
  <Text> - 7 Übernachtungen in der Pension direkt am Meer
  </Text>
  <Text> - Transfer vom und zum Flughafen zu Reisebeginn und Ende (an abweichenden Tagen: 150 €)
  </Text>
  <Text> - 12 x 90 Minuten Yogapraxis unter fachkundiger Anleitung 
  </Text>
  <Text> - täglich frisch zubereitetes vegetarisches Frühstück aus lokalen Produkten
  </Text>
  <Text> - Nutzung der hochwertigen, veganen und aus Kautschuk bestehenden Yogamatten von Zencore Yoga
  </Text>
  <Text> - Nutzung eines voll ausgestatteten Yogastudios (Decken, Blöcke, Bolster) mit Meerblick
  </Text>
  <Text> - Nutzung des Roof Top Pools und der Roof Top Yoga Base
  </Text>
  <Text> - Nutzung der Stand Up Paddels
  </Text>
  <br />
  Die Buchung läuft direkt über <Elink
  href="https://www.yogaretreatgreece.com/service-page/hatha-restorative-yoga?referral=service_list_widget"
  title="Sea&Soul"> Sea & Soul.
</Elink>{" "}
<br />
<br />
<Stack direction='row'>
  <Image boxSize='33%' objectFit='cover' src={haus1} alt='Haus1'/>
  <Image boxSize='33%' objectFit='cover' src={haus2} alt='Haus2'/>
  <Image boxSize='33%' objectFit='cover' src={haus3} alt='Haus3'/>
</Stack>
<br />
<br />
<Box textAlign="center">
      <Text frontSize="lg" as="b">
      Sichere dir einen Rabatt von 10% bis zum 31.12.2022!
      </Text>
</Box>
</Layout>
  )
}
